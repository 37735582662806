import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { AiFillHome } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { FiMoreHorizontal } from "react-icons/fi";
import {FaBluetooth} from "react-icons/fa"
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import translate from "translate";
import { useState,useEffect } from "react";

import "../css/BottomNav.css";
import "../css/global.css";

translate.engine = "google";
translate.key = process.env.DEEPL_KEY;

const Navbar = ({ value }) => {

    const [home, setHome] = useState("");
    const [customer, setCustomer] = useState("");
    const [more, setMore] = useState("");

    const localstorageValue = localStorage.getItem("i18nextLng") || "en_US";

  useEffect(() => {

    getHomeData();
    
  }, []);

  const getHomeData =()=>{
    const translateText = async () => {
        let translationHome = "";
        let translationCustomer = "";
        let translationMore = "";
        try {
            if(localstorageValue === "hi_IN"){
                translationHome = await translate("Home", { from: "en", to: "hi" }); 
                translationCustomer = await translate("Customers", { from: "en", to: "hi" });
                translationMore = await translate("More", { from: "en", to: "hi" });
             
            }else if(localstorageValue === "kn_IN"){
                translationHome = await translate("Home", { from: "en", to: "kn" }); 
                translationCustomer = await translate("Customers", { from: "en", to: "kn" });
                translationMore = await translate("More", { from: "en", to: "kn" });
             
            }else if(localstorageValue === "ml_IN"){
                translationHome = await translate("Home", { from: "en", to: "ml" }); 
                translationCustomer = await translate("Customers", { from: "en", to: "ml" });
                translationMore = await translate("More", { from: "en", to: "ml" });
            
            }else if(localstorageValue === "ta_IN"){
                translationHome = await translate("Home", { from: "en", to: "ta" }); 
                translationCustomer = await translate("Customers", { from: "en", to: "ta" });
                translationMore = await translate("More", { from: "en", to: "ta" });
            
            }else if(localstorageValue === "te_IN"){
                translationHome = await translate("Home", { from: "en", to: "te" }); 
                translationCustomer = await translate("Customers", { from: "en", to: "te" });
                translationMore = await translate("More", { from: "en", to: "te" });
            
            }else{ 
            
              translationHome = "Home";
              translationCustomer = "Customers";
              translationMore = "More";
            
            }
             
          } catch (error) {
            console.error("Translation error:", error);
          }
          setHome(translationHome); 
          setCustomer(translationCustomer);
          setMore(translationMore);
        
        };
        translateText();
  }


    return (
        <div>
            <BottomNavigation
                sx={{ width: "100%", position: "fixed", bottom: 0,height:"10vh" }}
                value={value}
            >
                <BottomNavigationAction
                    label={home}
                    icon={<AiFillHome style={{height:"35%",width:"35%"}}/>}
                    showLabel={true}
                    LinkComponent={Link}
                    to="/home"
                />
                <BottomNavigationAction
                    label={customer}
                    icon={<BiUserCircle style={{height:"35%",width:"35%"}}/>}
                    showLabel={true}
                    LinkComponent={Link}
                    to="/customer"
                />
                <BottomNavigationAction
                    label={more}
                    icon={<FiMoreHorizontal style={{height:"35%",width:"35%"}}/>}
                    showLabel={true}
                    LinkComponent={Link}
                    to="/more"
                />

                {/* <BottomNavigationAction
                    label="Bluetooth"
                    icon={<FaBluetooth style={{height:"35%",width:"35%"}}/>}
                    showLabel={true}
                    LinkComponent={Link}
                    to="/print"
                /> */}
            </BottomNavigation>
        </div>
    );
};

export default Navbar;
